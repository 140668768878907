<template>
  <div class="container-fluid">
    <div>
      <div class="alert alert-warning alert-dismissible fade show" role="alert" v-if="error" @click="error=''">
        {{error}}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <div class="row">
          <div class="col-4">Товары</div>
          <div class="col-2">
            <div class="row">
              <div class="col-6">WB ООО</div>
              <div class="col-6">WB ИП</div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-2">ЦСЛ</div>
              <div class="col-2">Neo</div>
              <div class="col-2">Cooper</div>
              <div class="col-2">Alcon</div>
              <div class="col-2">Eye</div>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="isLoading">
          <template #loading>
              <b-skeleton class="mb-3 mt-2"></b-skeleton>
              <b-skeleton class="mb-3"></b-skeleton>
              <b-skeleton class="mb-3"></b-skeleton>
              <b-skeleton class="mb-3"></b-skeleton>
              <b-skeleton class="mb-3"></b-skeleton>
              <b-skeleton class="mb-3"></b-skeleton>
            <div class="text-center" v-if="isLoading">
              <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
            </div>
          </template>
        </b-skeleton-wrapper>

        <products-table-astigmatism v-for="(types, indx_type) in astigmatism" :key="types.type" :types="types" :indx_type="indx_type" />
        <products-table v-for="(types, indx_type) in productsList" :key="types.type" :types="types" :indx_type="indx_type" />
        <products-table-rastvor v-for="(types, indx_type) in rastvor" :key="types.type" :types="types" :indx_type="indx_type" />
      </div>
    </div>


<!--<div class="row">-->
<!--  <ProductsTable :products-list="productsList" />-->
<!--</div>-->

  </div>
</template>



<script>
import ProductsTableAstigmatism from "@/components/oldPproducts/ProductsTableAstigmatism";
import ProductsTable from "@/components/oldPproducts/ProductsTable";
import ProductsTableRastvor from "@/components/oldPproducts/ProductsTableRastvor";

export default {
  name: "products-old-page",
  data: () => ({
    isLoading: false,
    astigmatism: [],
    productsList: [],
    rastvor: [],
    error: '',
    data: []
  }),
  methods: {
    async getData() {
      this.isLoading = true
      const readData = JSON.parse(localStorage.getItem('oldProd'))
      if (!readData) {
        try {
          const data = await fetch(`http://${process.env.VUE_APP_API_SERVER}:${process.env.VUE_APP_API_PORT}/report/oldProd`, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
              'dildo-token': this.$cookies.get("dildo-token")
            }
          })
          this.data = await data.json()
          localStorage.setItem('oldProd', JSON.stringify(this.data))
        } catch (err) {
          this.error = err
        }

          this.astigmatism = this.data.filter(e => e.type == 'Астигматические')
          this.rastvor = this.data.filter(e => e.type == 'Растворы')
          this.productsList = this.data.filter(e => e.type != 'Астигматические' && e.type != 'Растворы')
          this.isLoading = false

      }
    },
  },
  components: {
    ProductsTableRastvor,
    ProductsTableAstigmatism,
    ProductsTable,
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});
    if (dildoToken) {
      await this.getData()
    }

  }
};
</script>