<template>
<div class="row main-row shadow-sm rounded border border-2 mb-3">

    <div class="col-4 border-end" v-b-toggle="'level0-'+indx_type">
      {{types.type}} ({{types.allQty}})
    </div>
    <div class="col-2">
      <div class="row" style="font-size: 12px;">
        <div class="col-6 border-end" :class="classAllqty(types.onWbOOO, types.allQty)">{{ types.onWbOOO }}/{{ types.allQty }}</div>
        <div class="col-6 border-end border-3" :class="classAllqty(types.onWbIP, types.allQty)">{{ types.onWbIP }}/{{ types.allQty }}</div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-2 border-end">{{ types.onOzonCenterSklad }}/{{ types.allQty }}</div>
        <div class="col-2 border-end">{{ types.onOzonNeo }}/{{ types.allQty }}</div>
        <div class="col-2 border-end">{{ types.onOzonCooper }}/{{ types.allQty }}</div>
        <div class="col-2 border-end">{{ types.onOzonAlcon }}/{{ types.allQty }}</div>
        <div class="col-2 border-end">{{ types.onOzonEye }}/{{ types.allQty }}</div>
      </div>
    </div>

    <b-collapse :id="'level0-'+indx_type" class="mb-2 col-12">
      <div class="row level0 border" v-for="(models, indx_models) in types.info" :key="models.modelName">
        <div class="col-4 border-end" v-b-toggle="`level1-${indx_type}-${indx_models}`">{{models.modelName}} ({{models.allQty}})</div>
        <div class="col-2">
          <div class="row">
            <div class="col-6 border-end" :class="classAllqty(models.onWbOOO, models.allQty)">{{ models.onWbOOO }}/{{ models.allQty }}</div>
            <div class="col-6 border-end border-3" :class="classAllqty(models.onWbIP, models.allQty)">{{ models.onWbIP }}/{{ models.allQty }}</div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-2 border-end">{{ models.onOzonCenterSklad }}/{{ models.allQty }}</div>
            <div class="col-2 border-end">{{ models.onOzonNeo }}/{{ models.allQty }}</div>
            <div class="col-2 border-end">{{ models.onOzonCooper }}/{{ models.allQty }}</div>
            <div class="col-2 border-end">{{ models.onOzonAlcon }}/{{ models.allQty }}</div>
            <div class="col-2 border-end">{{ models.onOzonEye }}/{{ models.allQty }}</div>
          </div>
        </div>
        <b-collapse :id="`level1-${indx_type}-${indx_models}`" class="mb-2 col-12">
          <div class="row level1 border" v-for="(cyls, indx_cyls) in models.info" :key="cyls.key+indx_cyls">
            <div class="col-4 border-end" v-b-toggle="`level2-${indx_type}-${indx_models}-${indx_cyls}`">{{cyls.key}} ({{cyls.allQty}})</div>
            <div class="col-2">
              <div class="row">
                <div class="col-6 border-end" :class="classAllqty(cyls.onWbOOO, cyls.allQty)">{{ cyls.onWbOOO }}/{{ cyls.allQty }}</div>
                <div class="col-6 border-end border-3" :class="classAllqty(cyls.onWbIP, cyls.allQty)">{{ cyls.onWbIP }}/{{ cyls.allQty }}</div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-2 border-end">{{ cyls.onOzonCenterSklad }}/{{ cyls.allQty }}</div>
                <div class="col-2 border-end">{{ cyls.onOzonNeo }}/{{ cyls.allQty }}</div>
                <div class="col-2 border-end">{{ cyls.onOzonCooper }}/{{ cyls.allQty }}</div>
                <div class="col-2 border-end">{{ cyls.onOzonAlcon }}/{{ cyls.allQty }}</div>
                <div class="col-2 border-end">{{ cyls.onOzonEye }}/{{ cyls.allQty }}</div>
              </div>
            </div>

            <b-collapse :id="`level2-${indx_type}-${indx_models}-${indx_cyls}`" class="col-12">
              <div class="row level2 border" v-for="(ax, indx_ax) in cyls.info" :key="ax.key+indx_ax">
                <div class="col-4 border-end" v-b-toggle="`level3-${indx_type}-${indx_models}-${indx_cyls}-${indx_ax}`" @click="loadMoreInfo(`level3-${indx_type}-${indx_models}-${indx_cyls}-${indx_ax}`)">{{ax.key}} ({{ax.allQty}})</div>
                <div class="col-2">
                  <div class="row">
                    <div class="col-6 border-end" :class="classAllqty(ax.onWbOOO, ax.allQty)">{{ ax.onWbOOO }}/{{ ax.allQty }}</div>
                    <div class="col-6 border-end border-3" :class="classAllqty(ax.onWbIP, ax.allQty)">{{ ax.onWbIP }}/{{ ax.allQty }}</div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-2 border-end">{{ ax.onOzonCenterSklad }}/{{ ax.allQty }}</div>
                    <div class="col-2 border-end">{{ ax.onOzonNeo }}/{{ ax.allQty }}</div>
                    <div class="col-2 border-end">{{ ax.onOzonCooper }}/{{ ax.allQty }}</div>
                    <div class="col-2 border-end">{{ ax.onOzonAlcon }}/{{ ax.allQty }}</div>
                    <div class="col-2 border-end">{{ ax.onOzonEye }}/{{ ax.allQty }}</div>
                  </div>
                </div>

                <b-collapse :id="`level3-${indx_type}-${indx_models}-${indx_cyls}-${indx_ax}`" class="col-12">
                  <div class="row level3 border" v-for="(item, indx_item) in ax.info" :key="item.barcode+indx_item">
                    <div class="col-4 border-end text-end">{{item.name}} {{item.attr}}</div>
                    <div class="col-2">
                      <barcode-info-wb :item="item" :barcode="item.barcode" v-if="shows.includes(`level3-${indx_type}-${indx_models}-${indx_cyls}-${indx_ax}`)" />
                    </div>
                    <div class="col-6">
                      <barcode-info-ozon :item="item" :barcode="item.barcode" v-if="shows.includes(`level3-${indx_type}-${indx_models}-${indx_cyls}-${indx_ax}`)" />
                    </div>

                  </div>
                </b-collapse>


              </div>
            </b-collapse>
          </div>
        </b-collapse>

      </div>

    </b-collapse>


  </div>


</template>

<script>

import BarcodeInfoWb from "@/components/oldPproducts/BarcodeInfoWb";
import BarcodeInfoOzon from "@/components/oldPproducts/BarcodeInfoOzon";
export default {
  props: ['types', 'indx_type'],
  data: () => ({
    shows: []
  }),
  methods: {
    sendToSidebar(data) {
      this.$emit('opensidebar', data)
    },
    sendToSidebarOzon(data) {
      this.$emit('opensidebarozon', data)
    },
    loadMoreInfo(index) {
      this.shows.push(index)
    },
    classAllqty(onWb,allQty) {
      if (onWb==allQty) return 'text-success'
      if (allQty-onWb<50) return 'text-dark'
      if (allQty-onWb>50) return 'text-danger'
      if (onWb==0) return 'text-danger'
      return 'text-dark'
    },
  },
  components: {BarcodeInfoWb, BarcodeInfoOzon},
  async mounted() {

    }
}
</script>


<style scoped>
.main-row {
  font-size: 14px;
  font-color: #f00;
  font-weight: bold;
}
.main-row .level0 {
  font-size: 12px;
  font-color: #000;
  font-weight: normal;
  /*margin-right: -12px;*/
}
.main-row .level1 {
  font-size: 12px;
  font-color: #000;
  font-weight: normal;
  border-left-width: 5px!important;
  /*margin-right: -8px;*/
}
.main-row .level2 {
  font-size: 12px;
  font-color: #000;
  font-weight: normal;
  border-left-width: 10px!important;
  /*margin-right: -3px;*/
}
.main-row .level3 {
  font-size: 10px;
  font-color: #000;
  font-weight: normal;
  border-left-width: 15px!important;
  /*margin-right: 2px;*/
}

</style>