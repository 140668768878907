<template>
  <div class="row">
<!--    <div class="col-2 border-end not-found" :class="{'normal':ozonInfo.product_id && ozonInfo.supl=='CenterSklad'}">-->
<!--      <span v-if="isLoading">load...</span>-->
<!--      <span  v-if="ozonInfo.product_id && ozonInfo.supl=='CenterSklad'">-->
<!--         <a v-b-toggle.sidebar-no-header-ozon href="#" @click.prevent="$emit('myeventforozon', {ozonInfo})" target="_blank">{{ozonInfo.offer_id}}</a>-->
<!--      </span>-->
<!--      <span v-else>нет на сайте </span>-->
<!--    </div>-->
<!--    <div class="col-2 border-end not-found" :class="{'normal':ozonInfo.product_id && ozonInfo.supl=='Neo'}">-->
<!--      <span v-if="isLoading">load...</span>-->
<!--      <span  v-if="ozonInfo.product_id && ozonInfo.supl=='Neo'">-->
<!--         <a v-b-toggle.sidebar-no-header-ozon href="#" @click.prevent="$emit('myeventforozon', {ozonInfo})" target="_blank">{{ozonInfo.offer_id}}</a>-->
<!--      </span>-->
<!--      <span v-else>нет на сайте </span>-->
<!--    </div>-->
<!--    <div class="col-2 border-end not-found" :class="{'normal':ozonInfo.product_id && ozonInfo.supl=='Cooper'}">-->
<!--      <span v-if="isLoading">load...</span>-->
<!--      <span  v-if="ozonInfo.product_id && ozonInfo.supl=='Cooper'">-->
<!--         <a v-b-toggle.sidebar-no-header-ozon href="#" @click.prevent="$emit('myeventforozon', {ozonInfo})" target="_blank">{{ozonInfo.offer_id}}</a>-->
<!--      </span>-->
<!--      <span v-else>нет на сайте </span>-->
<!--    </div>-->
<!--    <div class="col-2 border-end not-found" :class="{'normal':ozonInfo.product_id && ozonInfo.supl=='Alcon'}">-->
<!--      <span v-if="isLoading">load...</span>-->
<!--      <span  v-if="ozonInfo.product_id && ozonInfo.supl=='Alcon'">-->
<!--         <a v-b-toggle.sidebar-no-header-ozon href="#" @click.prevent="$emit('myeventforozon', {ozonInfo})" target="_blank">{{ozonInfo.offer_id}}</a>-->
<!--      </span>-->
<!--      <span v-else>нет на сайте </span>-->
<!--    </div>-->
<!--    <div class="col-2 border-end not-found" :class="{'normal':ozonInfo.product_id && ozonInfo.supl=='Eye'}">-->
<!--      <span v-if="isLoading">load...</span>-->
<!--      <span  v-if="ozonInfo.product_id && ozonInfo.supl=='Eye'">-->
<!--         <a v-b-toggle.sidebar-no-header-ozon href="#" @click.prevent="$emit('myeventforozon', {ozonInfo})" target="_blank">{{ozonInfo.offer_id}}</a>-->
<!--      </span>-->
<!--      <span v-else>нет на сайте </span>-->
<!--    </div>-->

<!--  <div class="row" v-for="item of ozonInfo" :key="item.offer_id">-->
    <div class="col-2 border-end not-found" :class="{'normal':ozonInfo.filter(e => e.supl=='CenterSklad').length>0}">
      <span  v-if="ozonInfo.filter(e => e.supl=='CenterSklad').length>0">
         <a
             v-b-toggle.sidebar-no-header-ozon
             href="#"
             v-for="item of ozonInfo.filter(e => e.supl=='CenterSklad')" :key="item.offer_id"
             @click.prevent="$emit('myeventforozon', {item})"
             target="_blank">
          {{item.offer_id}}
         </a>
      </span>
      <span v-else>нет на сайте </span>
    </div>
    <div class="col-2 border-end not-found" :class="{'normal':ozonInfo.filter(e => e.supl=='Neo').length>0}">
      <span  v-if="ozonInfo.filter(e => e.supl=='Neo').length>0">
         <a
             v-b-toggle.sidebar-no-header-ozon
             href="#"
             v-for="item of ozonInfo.filter(e => e.supl=='Neo')" :key="item.offer_id"
             @click.prevent="$emit('myeventforozon', {item})"
             target="_blank">
          {{item.offer_id}}
         </a>
      </span>
      <span v-else>нет на сайте </span>
    </div>
    <div class="col-2 border-end not-found" :class="{'normal':ozonInfo.filter(e => e.supl=='Cooper').length>0}">
      <span  v-if="ozonInfo.filter(e => e.supl=='Cooper').length>0">
         <a
             v-b-toggle.sidebar-no-header-ozon
             href="#"
             v-for="item of ozonInfo.filter(e => e.supl=='Cooper')" :key="item.offer_id"
             @click.prevent="$emit('myeventforozon', {item})"
             target="_blank">
          {{item.offer_id}}
         </a>
      </span>
      <span v-else>нет на сайте </span>
    </div>
    <div class="col-2 border-end not-found" :class="{'normal':ozonInfo.filter(e => e.supl=='Alcon').length>0}">
      <span  v-if="ozonInfo.filter(e => e.supl=='Alcon').length>0">
         <a
             v-b-toggle.sidebar-no-header-ozon
             href="#"
             v-for="item of ozonInfo.filter(e => e.supl=='Alcon')" :key="item.offer_id"
             @click.prevent="$emit('myeventforozon', {item})"
             target="_blank">
          {{item.offer_id}}
         </a>
      </span>
      <span v-else>нет на сайте </span>
    </div>
    <div class="col-2 border-end not-found" :class="{'normal':ozonInfo.filter(e => e.supl=='Eye').length>0}">
      <span  v-if="ozonInfo.filter(e => e.supl=='Eye').length>0">
         <a
             v-b-toggle.sidebar-no-header-ozon
             href="#"
             v-for="item of ozonInfo.filter(e => e.supl=='Eye')" :key="item.offer_id"
             @click.prevent="$emit('myeventforozon', {item})"
             target="_blank">
          {{item.offer_id}}
         </a>
      </span>
      <span v-else>нет на сайте </span>
    </div>
<!--  </div>-->

  </div>
</template>

<script>
export default {
  props: ["barcode", "item"],
  name: 'infobarcode-ozon',
  data: () => ({
    isLoading: false,
    error: '',
    ozonInfo: []
  }),
  methods: {},
  async mounted() {
    this.isLoading = true
    // console.log(this.item)
  },
};
</script>

<style scoped>
.not-found {
  background-color: rgba(220, 53, 69, 0.3) !important;
}
.normal {
  background-color: rgba(25, 135, 84, 0.3) !important;
}

</style>
