<template>
<div class="row main-row shadow-sm rounded border border-2 mb-3">

    <div class="col-4 border-end" v-b-toggle="'level00-'+indx_type">
      {{types.type}} ({{types.allQty}})
    </div>
    <div class="col-2">
      <div class="row" style="font-size: 12px;">
        <div class="col-6 border-end " :class="classAllqty(types.onWbOOO, types.allQty)">{{ types.onWbOOO }}/{{ types.allQty }}</div>
        <div class="col-6 border-end border-3 " :class="classAllqty(types.onWbIP, types.allQty)">{{ types.onWbIP }}/{{ types.allQty }}</div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-2 border-end">{{ types.onOzonCenterSklad }}/{{ types.allQty }}</div>
        <div class="col-2 border-end">{{ types.onOzonNeo }}/{{ types.allQty }}</div>
        <div class="col-2 border-end">{{ types.onOzonCooper }}/{{ types.allQty }}</div>
        <div class="col-2 border-end">{{ types.onOzonAlcon }}/{{ types.allQty }}</div>
        <div class="col-2 border-end">{{ types.onOzonEye }}/{{ types.allQty }}</div>
      </div>
    </div>

    <b-collapse :id="'level00-'+indx_type" class="mb-2 col-12">
      <div class="row level00 border" v-for="(models, indx_models) in types.info" :key="models.modelName">
        <div class="col-4 border-end" v-b-toggle="`level11-${indx_type}-${indx_models}`">{{models.modelName}} ({{models.allQty}})</div>
        <div class="col-2">
          <div class="row">
            <div class="col-6 border-end" :class="classAllqty(models.onWbOOO, models.allQty)">{{ models.onWbOOO }}/{{ models.allQty }}</div>
            <div class="col-6 border-end border-3" :class="classAllqty(models.onWbIP, models.allQty)">{{ models.onWbIP }}/{{ models.allQty }}</div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-2 border-end">{{ models.onOzonCenterSklad }}/{{ models.allQty }}</div>
            <div class="col-2 border-end">{{ models.onOzonNeo }}/{{ models.allQty }}</div>
            <div class="col-2 border-end">{{ models.onOzonCooper }}/{{ models.allQty }}</div>
            <div class="col-2 border-end">{{ models.onOzonAlcon }}/{{ models.allQty }}</div>
            <div class="col-2 border-end">{{ models.onOzonEye }}/{{ models.allQty }}</div>
          </div>
        </div>
        <b-collapse :id="`level11-${indx_type}-${indx_models}`" class="mb-2 col-12">
          <div class="row level11 border" v-for="(groper, indx_groper) in models.info" :key="groper.key+indx_groper">
            <div class="col-4 border-end" v-b-toggle="`level33-${indx_type}-${indx_models}-${indx_groper}`" @click="loadMoreInfo(`level33-${indx_type}-${indx_models}-${indx_groper}`)">{{groper.key}} ({{groper.allQty}})</div>
            <div class="col-2">
              <div class="row">
                <div class="col-6 border-end" :class="classAllqty(groper.onWbOOO, groper.allQty)">{{ groper.onWbOOO }}/{{ groper.allQty }}</div>
                <div class="col-6 border-end border-3" :class="classAllqty(groper.onWbIP, groper.allQty)">{{ groper.onWbIP }}/{{ groper.allQty }}</div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-2 border-end">{{ groper.onOzonCenterSklad }}/{{ groper.allQty }}</div>
                <div class="col-2 border-end">{{ groper.onOzonNeo }}/{{ groper.allQty }}</div>
                <div class="col-2 border-end">{{ groper.onOzonCooper }}/{{ groper.allQty }}</div>
                <div class="col-2 border-end">{{ groper.onOzonAlcon }}/{{ groper.allQty }}</div>
                <div class="col-2 border-end">{{ groper.onOzonEye }}/{{ groper.allQty }}</div>
              </div>
            </div>

            <b-collapse :id="`level33-${indx_type}-${indx_models}-${indx_groper}`" class="col-12">
              <div class="row level33 border" v-for="(item, indx_item) in groper.info" :key="item.barcode+indx_item">
                <div class="col-4 border-end text-end">{{item.name}} {{item.attr}}</div>
                <div class="col-2">
                      <barcode-info-wb :item="item" :barcode="item.barcode" v-if="shows.includes(`level33-${indx_type}-${indx_models}-${indx_groper}`)" />
                </div>
                <div class="col-6">
                  <barcode-info-ozon :item="item" :barcode="item.barcode" v-if="shows.includes(`level33-${indx_type}-${indx_models}-${indx_groper}`)" />
                </div>

              </div>
            </b-collapse>
          </div>
        </b-collapse>

      </div>

    </b-collapse>

  </div>


</template>

<script>

import BarcodeInfoWb from "@/components/oldPproducts/BarcodeInfoWb";
import BarcodeInfoOzon from "@/components/oldPproducts/BarcodeInfoOzon";
export default {
  components: {BarcodeInfoOzon, BarcodeInfoWb},
  props: ['types', 'indx_type'],
  data: () => ({
    shows: []
  }),
  methods: {
    classAllqty(onWb,allQty) {
      if (onWb==allQty) return 'text-success'
      if (allQty-onWb<50) return 'text-dark'
      if (allQty-onWb>50) return 'text-danger'
      if (onWb==0) return 'text-danger'

      return 'text-dark'
    },
    loadMoreInfo(index) {
      this.shows.push(index)
    },
    sendToSidebar(data) {
      this.$emit('opensidebar', data)
    },
    sendToSidebarOzon(data) {
      this.$emit('opensidebarozon', data)
    }
  },
    async mounted() {

    }
}
</script>


<style scoped>
.main-row {
  font-size: 14px;
  font-color: #f00;
  font-weight: bold;
}
.main-row .level00 {
  font-size: 12px;
  font-color: #000;
  font-weight: normal;
  /*margin-right: -12px;*/
  /*margin-left: 0px;*/
}
.main-row .level11 {
  font-size: 12px;
  font-color: #000;
  font-weight: normal;
  border-left-width: 5px!important;
  /*margin-right: -8px;*/
  /*margin-left: 0px;*/
}
.main-row .level33 {
  font-size: 10px;
  font-color: #000;
  font-weight: normal;
  border-left-width: 15px!important;
  /*margin-right: 2px;*/
  /*margin-left: 0px;*/
}

</style>