<template>
  <div class="row" v-if="item.article_wb">
    <div class="col-6" :class="getBgClass(item.article_wb.ooo)">
      <span v-if="isLoading">load...</span>
      <span v-if="item.article_wb.ooo.length>0">
         <a
             v-b-toggle.sidebar-no-header
             :href="
                'https://www.wildberries.ru/catalog/' +
                arts_wb +
                '/detail.aspx?targetUrl=XS'
              "
             v-for="(arts_wb, indx) in item.article_wb.ooo"
             :key="'ooo' + indx"
            target="_blank"
             :class="getLinkClass(arts_wb)"
             title="Артикул вб | транслируем на вб, наши остатки в мск"
             style="white-space:nowrap;"
         > {{arts_wb}} | {{(wbOstatki.length>0) ? wbOstatki.find(e => e.art_wb == arts_wb).wb + wbOstatki.find(e => e.art_wb == arts_wb).wbPssp : 'no'}}, {{curOstatki.mskIn+curOstatki.mskExport}} <br> </a>
      </span>
      <span v-else>нет </span>
    </div>
    <div class="col-6 border-3" :class="getBgClass(item.article_wb.ip)">
      <span v-if="isLoading">load...</span>
      <span v-if="item.article_wb.ip.length>0">
          <a
              v-b-toggle.sidebar-no-header
              :href="
                'https://www.wildberries.ru/catalog/' +
                arts_wb +
                '/detail.aspx?targetUrl=XS'
              "
              target="_blank"
              v-for="(arts_wb, indx) in item.article_wb.ip"
              :key="'ip' + indx"
              :class="getLinkClass(arts_wb)"
              title="Артикул вб | транслируем на вб, наши остатки в мск"
              style="white-space:nowrap;"
          >{{arts_wb}} | {{(wbOstatki.length>0) ? wbOstatki.find(e => e.art_wb == arts_wb).wb + wbOstatki.find(e => e.art_wb == arts_wb).wbPssp : 'no'}}, {{curOstatki.mskIn+curOstatki.mskExport}} <br> </a>

      </span>
      <span v-else>нет</span>

  </div>

  </div>
</template>

<script>
export default {
  props: ["barcode", "item"],
  data: () => ({
    curOstatki: {},
    wbOstatki: {},
    isLoading: false,
    error: '',
    ozonInfo: {},
  }),
  methods: {
    getBgClass(arts_wb) {
      if (arts_wb.length>0 && this.wbOstatki>0) {
      let status = 'not-found'
          arts_wb.map(e => {
          const {wbPssp, wb} = this.wbOstatki.find(el => el.art_wb == e) || {wb: 0, wbPssp: 0}
          const cc = this.curOstatki.mskIn+this.curOstatki.mskExport
          // console.log({
          //   art_wb: e,
          //   wb: ff.wbPssp+ff.wb,
          //   cur: cc
          // })
          if (wbPssp+wb>0 && cc>0) status = 'normal'
        })

        console.log({
          status, arts_wb
        })

    return status

      } else {
        return 'empty'
      }
    },
    getLinkClass(art_wb) {
      if (art_wb && this.wbOstatki.length>0) {
        const {wb, wbPssp} = this.wbOstatki.find(el => el.art_wb == art_wb)
        const {mskIn, mskExport} = this.curOstatki

        if (wb+wbPssp==0 && mskIn+mskExport>0) {

          return 'text-danger'
        }
        if (wb+wbPssp>0 && mskIn+mskExport>0) return 'text-success'
        if (wb+wbPssp==0 && mskIn+mskExport>=0) return 'text-danger'
      } else {
        return 'text-info'
      }




    }
  },
  async mounted() {
    this.isLoading = true
    try {
      const data = await fetch(`http://${process.env.VUE_APP_API_SERVER}:${process.env.VUE_APP_API_PORT}/report/ostatki/${this.barcode}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'dildo-token': this.$cookies.get("dildo-token"),
        }
      })
      this.curOstatki = await data.json()

      this.isLoading = false
    } catch (err) {
      this.error = err
    }

    try {
      const data = await fetch(`http://${process.env.VUE_APP_API_SERVER}:${process.env.VUE_APP_API_PORT}/report/wbostatki/${this.barcode}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'dildo-token': this.$cookies.get("dildo-token")
        }
      })
      this.wbOstatki = await data.json()
      this.isLoading = false
    } catch (err) {
      this.error = err
      // this.wbOstatki = {wb: 0, wbPssp: 0}
    }
    // console.log({
    //   curOstatki: this.curOstatki,
    //   wbOstatki: this.wbOstatki,
    //   barcode: this.barcode
    // })

  },
};
</script>

<style scoped>
.not-found {
  background-color: rgba(220, 53, 69, 0.3) !important;
}
.normal {
  background-color: rgba(25, 135, 84, 0.3) !important;
}
.empty {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

</style>
